import React from 'react'
import Book from "../components/book"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const gotostripe = () => {
  window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
  
}

function RegistrationandEligibility() {
  return (
    <div>
      <div className="container">
        
        <div className='row rowpad1' data-aos="fade-up">
              <div className='col' style={{paddingTop: "50px"}}>
                  <p className="maintitle">International Research Olympiad Registration</p>
              </div>

                <p className="lowertitle">
                  The International Research Olympiad has a registration fee of <b>$25 USD</b> for the Opens round. Learn about fee waivers for students with financial need <a style={{color: "black"}} href="https://docs.google.com/document/d/e/2PACX-1vTyIKUZUEMqA3uQ_EFFpJyYDPHINHfNZTe44IhyLyP5Rgny1hy5La0weR56dFYah9cY6fCByXQqKluf/pub" target="blank">here</a>. The costs for the subsequent semifinals and finals round are covered by the International Research Olympiad and its sponsors. In the 2023-24 edition of the contest, the International Research Olympiad had students fly to Cambridge, MA, USA for the in-person finals. As an academic organization, the International Research Olympiad does not earn profits from the competition, and registration fees are solely utilized for development of the IRO exam platform, marketing and advertisement, and contest expenses.  
                </p>

              <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
                <p className="secondarytitle">International Research Olympiad Eligibility Information</p>
              </div>

              <p className="lowertitle" data-aos="fade-up">
                To be eligible to compete, you must meet <span className="at-least-one-css">at least one</span> of the following criteria:
                </p>

                <ul class="list-group list-group-flush">
                 <li class="list-group-item"></li>
                  <li class="list-group-item">Be enrolled in a recognized high school/secondary school or homeschool program. <br/> This includes both traditional public and private high schools, as well as accredited online programs.</li>
                  <li class="list-group-item">Be under 18 years old as of March 8, 2025. <br/>Age may be verified using official documentation, such as a government-issued photo ID or birth certificate.</li>
                  <br/><br/>
                </ul>

                <p className="lowertitle" data-aos="fade-up">
                If you meet either of these criteria, Great! You qualify to participate in the International Research Olympiad. Be sure to register for the competition!
                </p>

                <div style={{paddingTop: "20px"}}></div>
                <Book/>
                

          </div>
      </div>
    </div>
  )
}

export default RegistrationandEligibility
