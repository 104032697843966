import './App.css';
import Book from './components/book';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import logo from './irofullpng.webp'; 
import headerfinalist from "./finalist_photos/headerfinalsm-min.webp"
import logotwo from './logos/plogo.png'; 
import logotwo1 from './logos/halfglobe.png'; 
import logotwo2 from './logos/hypelogo.png'; 
import logotwo3 from './logos/samyaklogo.png'; 
import finalisttakingpic from "./finalist_photos/finalstakingpic-min.webp"
import logotwo5 from './logos/ylogo.png'; 
import olpiamdhub from "./logos/perpolympiadhub.png"
import logofunny from './logos/Fruit_logo.png'; 
import lurmerelogo from './logos/lumerelogo.png';
import biologo from './logos/biologo.png'; 
import dialogo from "./logos/dialogogo.jpg"
import qlogo from "./logos/Qurios.jpg"
import googlelogo from './logos/google.png'; 
import whyiroo from "./finalist_photos/finalswalkingoutside-min.webp"
import loomlogo from './logos/newloom.png'; 
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import Backdrop from '@mui/material/Backdrop';
import { useInView } from "react-intersection-observer";

import { useState, useEffect, forwardRef, Fragment } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import firebase from "firebase/compat/app";
import db from './firebase'
import { Margin } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import "aos/dist/aos.css"; 
import { TypeAnimation } from 'react-type-animation';
import AOS from "aos";
import uuid from 'react-uuid';
import Marquee from "react-fast-marquee";
import BoardofAdvisors from './pages/BoardofAdvisors';
import CountUp from 'react-countup';

import { ReactTyped } from "react-typed";
import Partners from './components/partners';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function Home() {

  const [email, setEmail] = useState('');

  const [uID, setUID] = useState(false); 
  const [iroUid, setIroUid] = useState('');

  const [pageLoad1, setPageLoad1] = useState(false);
  const [pageLoad2, setPageLoad2] = useState(false);
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0 });

  useEffect(() => {
    if (!pageLoad1 && inView1) {
      setPageLoad1(true);
      createIframe('.video-responsive1');
    }
  }, [inView1, pageLoad1]);

  useEffect(() => {
    if (!pageLoad2 && inView2) {
      setPageLoad2(true);
      createIframe('.video-responsive2');
    }
  }, [inView2, pageLoad2]);

  const createIframe = (selector) => {
    let iframe = document.createElement('iframe');
    iframe.setAttribute('src', 'https://www.youtube.com/embed/LmKxVI8i3iU?si=REndfM9Y0Jy1sEI&autoplay=1&mute=1');
    iframe.setAttribute('frameBorder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowFullScreen', '');
    iframe.setAttribute('width', '853');
    iframe.setAttribute('height', '480');

    let videoResponsiveElement = document.querySelector(selector);
    if (videoResponsiveElement) {
      videoResponsiveElement.appendChild(iframe);
    } else {
      console.error(`Element with selector '${selector}' not found.`);
    }
  };


  const [successOpenMUI, setSuccessOpenMUI] = useState(false);
  const [failOpenMUI, setFailOpenMUI] = useState(false);
  const trueOrFalseDisableButton = JSON.parse(localStorage.getItem('trueOrFalseDisableButton'));
  const [enterEmailButtonDisable, setEnterEmailButtonDisable] = useState(trueOrFalseDisableButton);
  const [open, setOpen] = useState(false);
  const [documentCount, setDocumentCount] = useState([]);
  const navigate = useNavigate();

  const targetDate = new Date('March 3, 2025 00:00:00').getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  

  function calculateTimeRemaining() {
    const currentDate = new Date().getTime();
    const difference = targetDate - currentDate;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return {
      days,
      hours,
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);




  const handleCloseIn = () => {
    setOpen(false);
  };

  const loginpage = () => {
    navigate("/register")
  
  }

  const handleOpenIn = () => {
    setOpen(true);
  };

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }


  useEffect(() => {
    AOS.init({ duration: 2000 }); //might need to be AOS
  }, []);


  useEffect(() => {
    db.collection("Submits").onSnapshot((snapshot) =>
      setDocumentCount(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);


  
  const handleCloseMUI = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpenMUI(false);
    setFailOpenMUI(false);
    setUID(false)
  };

  const increment = firebase.firestore.FieldValue.increment(1);

  
  const EmailChecker = async () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let today = new Date();
  
    if (re.test(email)) {
      setSuccessOpenMUI(true);
      setEnterEmailButtonDisable(true);
      localStorage.setItem('trueOrFalseDisableButton', JSON.stringify(true));
      db.collection("IRO").add({
        email: email,
        date: today,
      });
      var storyRef = db.collection("Submits").doc("countval");
      storyRef.update({ submitcounts: increment });
      
      try {
        await axios.post('/api/submitEmail', { email });
        console.log('POST request sent successfully');
      } catch (error) {
        console.error('Error sending POST request:', error);
      }
      
      setEmail('');
    } else {
      setFailOpenMUI(true);
      setEnterEmailButtonDisable(false);
      localStorage.setItem('trueOrFalseDisableButton', JSON.stringify(false));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <div className="App"> 

      
    
      <div className="">

        <div className=" containere" data-aos="zoom-in-up">

          <div onClick={loginpage} className="infoe">
            <p style={{marginTop: '8px', fontSize: '16px'}}>
              {/* <strong className="sdflknsrj" style={{fontSize: '16px', fontFamily: "serif"}}>Register before it's too late!</strong> */}
              <Typography variant="overline" >
              <b >ANNouncement:</b> IRO 2025 Registration Opened
              </Typography>
              <a href="/register" style={{color: "black"}} placement="right-start">
                <ArrowForwardIcon style={{marginBottom: "2px"}}/>
              </a>
            </p>
            
          </div>
        </div>



      </div>

    
      <div className='container'>

      <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
            open={open}
            onClick={handleCloseIn}
        >

            <div className="row rowpad1" style={{padding:'20px', overflowY: 'scroll'}}>
            <div className="col" data-aos="fade-right" >
                <p className="importitle"> <InfoOutlinedIcon style={{fontSize: '50px'}}/> Important Information</p>
                <br className="br-big"/>
                <br className="br-big"/>
                <p className="lowertitle">The initial stage is the "Opens" round, which requires a $60 registration fee. <br/> This round is conducted online through our dedicated application, providing accessibity to registrants worldwide.</p>

                <br className="br-big"/>
                <p className="lowertitle"> <KeyboardDoubleArrowRightOutlinedIcon/> <b> Registration </b> Opens on January 2025 </p>

                <br className="br"/>
                <p className="lowertitle"> <KeyboardDoubleArrowRightOutlinedIcon/> <b> Opens</b> round is conducted in March 2025</p>

                <br className="br"/>
                <p className="lowertitle"> <KeyboardDoubleArrowRightOutlinedIcon/> <b> Semifinals </b> round is conducted in April 2025</p>

                <br className="br"/>
                <p className="lowertitle"> <KeyboardDoubleArrowRightOutlinedIcon/> <b> Finals </b> round is conducted in June 2025</p>
            
            </div>
            </div>
        </Backdrop>
        <div >
        
          <div className='row'>
          {/* <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Here is a gentle confirmation that your action was successful.
          </Alert> */}
            <div className='col' style={{paddingTop:"100px"}}>
              <h1 className="maintitletitle">International Research Olympiad</h1>
              <p className="lowertitle2">An olympiad dedicated to testing science research ability and developing critical thinking and analytical skills. Over 45+ countries were represented in the 2024 IRO. We have created the largest global community of young students interested in research.</p>
              {/* <p className="lowertitle"><b>IRO 2025 Registration Opened </b></p> */}

            
      
              <div class="row">

                {/* <div class="col">     
                  <TextField 
                    className="propfont" 
                    fullWidth id="standard-basic" 
                    label={email.trim() === '' ? 
                    <ReactTyped
        strings={[
          'Email',
          'example@gmail.com',
          'example@yahoo.com',
          'example@xyz.edu',
        ]}
        typeSpeed={110}
        loop
        backSpeed={70}
        cursorChar=""
        className="hdgfkajhsgdfkjshadgajhdsg-name"
        showCursor={false}
      /> : 'Email'}
                    variant="standard" 
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    hidden={enterEmailButtonDisable}
                  
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {email.trim() !== '' &&
                            <IconButton 
                              sx={{
                                '&:hover': {
                                  background: 'none',
                                },
                              }}
                              onClick={EmailChecker}
                              disabled={enterEmailButtonDisable}
                              hidden={enterEmailButtonDisable}
                            >
                              <ArrowForwardIcon 
                                style={{
                                  color:"black", 
                                  fontSize: '20px',
                                }}
                              />
                            </IconButton>
                          }     
                        </InputAdornment>
                      ),
                    }}
                    
                  />
                  <p hidden={!enterEmailButtonDisable} className="Response-submitted">Your response was successfully submitted.</p>
                

                </div> */}

                  <div className='col'>
                      
                      <button className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={loginpage}>IRO 2025 Registration Opened</button><ArrowForwardIcon style={{marginBottom: "7px"}}/>
                  </div>

                  <div style={{marginTop: '10px'}}>
                    <div className="hidden-button-css-id-32y23grjhdgfhjsfadfdj">
                      <span style={{color: "gray", fontSize:'17px'}}><CountUp start={0} end={`${documentCount.map(({ submitcounts }) => Math.round(submitcounts / 50) * 50)}`} duration={3}/>+ signups</span>
                    </div>

                    <div className="hidden-button-css-id-5645fglksjdhrgdfdff">
                      <span style={{color: "gray", fontSize:'17px'}}><CountUp start={0} end={`${documentCount.map(({ submitcounts }) => Math.round(submitcounts / 50) * 50)}`} duration={3}/>+ signups</span>
                    </div>
                  </div>
                
                
              </div>
              {!enterEmailButtonDisable && <br style={{marginTop: '10px'}}/>}
              {/* <div >
                <p className="lowertitle2" style={{marginBottom:'0px', padding:'0px'}}>Update:</p>
                <p className="lowertitle1">Finals will be hosted in-person at Harvard University; accommodations covered for finalists.</p>
              </div> */}
              {/* <p className="APPLY-BY-text">Apply by March 2025</p> */}
              {/* <Typography variant="overline" display="block" style={{paddingLeft: "10px"}} gutterBottom>
              Apply by March 2025
              </Typography> */}
              

             

              
            </div>
            <div class="col mainlscreen" data-aos="fade-up">
              
              <LazyLoad height={200} once offset={100}>
                <img className="mainimg" src={logo} height="auto"></img>
              </LazyLoad>
            </div>
          </div>

          <div className=" nottypurple">
              <div className="container">
                <div className="first-video row rowpad1 align-items-center" data-aos="fade-up">
                  <div className="col" style={{ marginLeft: '10%', marginRight: '10%' }} >
                    
                    <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ref={ref1}>
                      <div className="video-responsive1"></div>
                    </div>

                  </div>
                </div>
                <div className='row finalspressrelase rowpad1 align-items-center' data-aos="fade-up" >
                  <p style={{textAlign: "center"}} className="comptitle" > Winners of the 2024 IRO </p> 
                    
                    <p style={{textAlign: "center"}} className="lowertitle">
                      The International Research Olympiad Finals event took place from <b>May 31st to June 2nd, 2024 in Cambridge, MA, at Harvard Square.</b>
                      </p>
                      <p style={{textAlign: "center"}} className="lowertitle">
                      Rishab Kumar Jain, President and Chair of the Student Board of Advisors to the International Research Olympiad said “What sets the IRO apart is our commitment to critical thinking, which is essential for scientific research. We’ve seen a high number of contestants who possess extraordinary potential—and who are serious and motivated about their research-based, real-world interests. This is very exciting to us, and in the finals event, we were able to test and grow this potential.”
                      </p>
                      <p style={{textAlign: "center"}} className="lowertitle">
                      “The IRO Finals featured some research-based surprises for finalists, and students showed a level of adaptability and resilience that was remarkable,” said Executive & Education Coordinator Yashvir Sabharwal. “Each student tackled a crucial issue during their Public Science Communication speeches, and presented their solutions in a highly impactful way.”
                      </p>
                      <p style={{textAlign: "center"}} className="lowertitle">
                      The 2024 IRO Finals showcased some of the most promising young minds in the world. With its dedication to critical thinking and real-world application of knowledge, the IRO is shaping the next generation of innovative leaders who will tackle global challenges and make a lasting impact on society.
                      </p>

                      <a href="https://www.internationalresearcholympiad.org/press-releases" target='_blank' style={{textAlign: "center"}} className="lowertitle text-black" >
                        View the entire Press Release here
                      </a>

                </div>

               
            </div>
          </div>

          <div className=" nottypurplee">
              <div className="container">
                {/* <div style={{marginLeft: "10%", marginRight: "10%"}} className='row rowpad1 align-items-center' data-aos="fade-up" >
                  

                    <iframe width="500" height="405" style={{filter: "invert(100%)"}} src="https://www.youtube.com/embed/LmKxVI8i3iU?si=P5BMrJcnarl3DaTX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div> */}

                <div className="first-video row rowpad1 align-items-center" data-aos="fade-up">
                  <div style={{ marginLeft: '10%', marginRight: '10%' }} className="col">
                    <div style={{ filter: "invert(100%)", width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ref={ref2}>
                      {/* Placeholder for iframe */}
                      <div className="video-responsive2"></div>
                    </div>
                  </div>
                </div>


                <div className='row finalspressrelase rowpad1 align-items-center' data-aos="fade-up" >
                  <p style={{textAlign: "center"}} className="comptitle" > Winners of the 2025 IRO </p> 
                    
                    <p style={{textAlign: "center"}} className="lowertitle">
                      The International Finals Exam took place from <b>May 31st to June 2nd, 2025.</b>
                      </p>
                      <p style={{textAlign: "center"}} className="lowertitle">
                      Rishab Kumar Jain, President and Chair of the Student Board of Advisors to the International Research Olympiad said “What sets the IRO apart is our commitment to critical thinking, which is essential for scientific research. We’ve seen a high number of contestants who possess extraordinary potential—and who are serious and motivated about their research-based, real-world interests.”
                      </p>
                      <p style={{textAlign: "center"}} className="lowertitle">
                      “The IRO Finals took place in a time like no other, and students showed a level of adaptability and resilience that was remarkable,” said Executive & Education Coordinator Yashvir Sabharwal. “Each student tackled a crucial issue, and presented their solutions so that they would matter to the world today.”
                      </p>
                      <p style={{textAlign: "center"}} className="lowertitle">
                      The 2025 IRO Finals showcased some of the most promising young minds in the world. With its dedication to critical thinking and real-world application of knowledge, the IRO is shaping the next generation of innovative leaders who will tackle global challenges and make a lasting impact on society.
                      </p>

                      <a href="https://www.internationalresearcholympiad.org/press-releases" target='_blank' style={{textAlign: "center"}} className="lowertitle text-black" >
                        View the entire Press Release here
                      </a>

                </div>

               
            </div>
          </div>

         
        </div>

        {/* Block of stuff */}

        <Book/>

        

        
      </div>

        

         {/* Section 2 */}


        <div className="nottypurple">
          <div className="container">

          <div className='row rowpad1 align-items-center'>
              <div class="clearfix">
                <LazyLoad height={200} offset={100} once>
                    <img style={{width: "400px", border: "thick double #7d9ad4"}} src={finalisttakingpic} className="col-md-6 float-md-end mb-3 ms-md-3"/>
                </LazyLoad>

           

                <p className="comptitle" > 2024 International Finals Exam </p> 
                    
                    <p className="lowertitle">The International Finals Exam took place from <b>May 31st to June 2nd, 2024.</b></p>

                    <p className="lowertitle">The International Research Olympiad (IRO) is dedicated to honing analytical and critical thinking skills for student researchers—essential in scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested in the 2025 IRO examinations, allowing students to walk away with a deeper passion for scientific inquiry.</p>
              </div>
            </div>

            {/* ------------------------------REAL BOAR D------------------------------- */}

            <BoardofAdvisors />

            <div className='row gotdisatsm rowpad22 mainlinesectwo ' data-aos="fade-up" >
              <p className="comptitle" >Competition Format <IconButton onClick={handleOpen} style={{marginTop: '9px'}}><InfoOutlinedIcon style={{fontSize: '20px', color: 'black'}}/></IconButton></p> 
              <div id="sidelinesectwo" className='col-3'>
                <div className='row'>    
                  <p className="complowertitle"> <HowToRegIcon style={{fontSize: '40px'}}/> Registration</p>
                </div>

                <div className='row'>    
                  <div className="sepvert"></div>
                </div>
              
                <div className='row'>    
                  <p className="complowertitle" > <OpenInBrowserIcon style={{fontSize: '38px'}}/> Opens</p>
                </div>

                <div className='row'>    
                  <div className="sepvert"></div>
                </div>
                
                <div className='row'>    
                  <p className="complowertitle"> <MilitaryTechIcon style={{fontSize: '40px'}}/> Semifinals</p>
                </div>

                <div className='row'>    
                  <div className="sepvert"></div>
                </div>
              
                <div className='row'>    
                  <p className="complowertitle"> <EmojiEventsIcon style={{fontSize: '40px'}}/> Finals</p>
                </div>
              </div>
              <div id="secmainsectwo" className='col-9 '>
                <div className='row'>
                  <div className='col col-md' style={{marginRight: "20px", marginBottom: "20px"}}>
                    <p className="complowertitle"> <HowToRegIcon style={{fontSize: '40px'}}/> Registration</p>
                    <p className="date">January 2025</p>
                    <p className="lowertitle">

                Register by January, 2025 to participate in the IRO. Currently, registrations are closed, but you can put your email at the top of the page to be notified when they open. After registering, the IRO will create unique exam passwords for each student. Students will be sent their login information on March 4th, once registration is closed. Students will then compete in the Opens round.

                      
                    </p>
                  </div>
                  <div className='col col-md'  style={{marginBottom: "20px"}}>
                    <p className="complowertitle" > <OpenInBrowserIcon style={{fontSize: '40px'}}/> Opens</p>
                    <p className="date">March 2025</p>
                    <p className="lowertitle">
                      
                    The "Opens" round is a comprehensive assessment, encompassing diverse elements such as analyzing research papers, interpreting scientific data, and tackling question formation and inquiry. This stage aims to test participants’ analytical skills and critical thinking.

                    </p>
                  </div>
                  
                </div>
                <div className='row'>
                  <div className='col col-md' style={{marginRight: "20px"}}>
                    <p className="complowertitle"> <MilitaryTechIcon style={{fontSize: '40px'}}/> Semifinals</p>
                    <p className="date">April 2025</p>
                      <p className="lowertitle">
                        
                      From the Open examinations, the top 10% of participants will advance to the semifinals. The semifinals elevate the challenge of introducing free response questions judged by qualified researchers delving deeper into participants' ability to articulate and apply their understanding of scientific concepts.
                      </p>
                  </div>
                  <div className='col col-md' >
                    <p className="complowertitle"> <EmojiEventsIcon style={{fontSize: '40px'}}/> Finals</p>
                    <p className="date">June 2025; In-Person @ Harvard Square, Cambridge, MA</p>
                    <p className="lowertitle">
                      
                    The pinnacle of the competition is the finals, where the top 15 participants will engage in intense competition for the coveted gold, silver, and bronze medals in the IRO. Additional sponsor prizes, media opportunities, and recognition will be awarded.
                      
                      </p>
                  </div>
                  
                </div>
              </div>
            </div>


            <div className="Competition-Format-resize-when-small-screen-hide-none">
              <div className='container'>
                <div className='row rowpad2 mainlinesectwo' data-aos="fade-up">
                  <p className="comptitleee" style={{paddingBottom: "50px"}}>Competition Format <IconButton onClick={handleOpen} style={{marginTop: '9px'}}><InfoOutlinedIcon style={{fontSize: '17px', color: 'black'}}/></IconButton></p> 
                    <div >

                      <div className='row' style={{marginRight: "20px", marginBottom: "20px"}}>
                        <p className="complowertitle"> <HowToRegIcon style={{fontSize: '40px'}}/> Registration</p>
                        <p className="date">January 2025</p>
                        <p className="lowertitle">

                          Register by March 3rd, 11:59pm EST to participate in the IRO. After registering, the IRO will create unique exam passwords for each student. Students will be sent their login information on March 4th, once registration is closed. Students will then compete in the Opens round.

                          
                        </p>
                      </div>
                      <div className='row'  style={{marginBottom: "20px"}}>
                        <p className="complowertitle" > <OpenInBrowserIcon style={{fontSize: '40px'}}/> Opens</p>
                        <p className="date">March 2025</p>
                        <p className="lowertitle">
                          
                        The "Opens" round is a comprehensive assessment, encompassing diverse elements such as analyzing research papers, interpreting scientific data, and tackling question formation and inquiry. This stage aims to test participants’ analytical skills and critical thinking.

                        </p>
                      </div>
                      

                      <div className='row' style={{marginRight: "20px"}}>
                        <p className="complowertitle"> <MilitaryTechIcon style={{fontSize: '40px'}}/> Semifinals</p>
                        <p className="date">April 2025</p>
                          <p className="lowertitle">
                            
                          From the Open examinations, the top 10% of participants will advance to the semifinals. The semifinals elevate the challenge of introducing free response questions judged by qualified researchers delving deeper into participants' ability to articulate and apply their understanding of scientific concepts.
                          </p>
                      </div>
                      <div className='row' >
                        <p className="complowertitle"> <EmojiEventsIcon style={{fontSize: '40px'}}/> Finals</p>
                        <p className="date">June 2025</p>
                        <p className="lowertitle">
                          
                        The pinnacle of the competition is the finals, where the top 15 participants will engage in intense competition for the coveted gold, silver, and bronze medals in the IRO. Additional sponsor prizes, media opportunities, and recognition will be awarded.
                          
                          </p>
                      </div>

                  </div>
                </div>
              </div>
            </div>

           

          </div>
        </div>
        <div className="nottypurplee">
          <div className="container">
          <div className='row rowpad1 align-items-center'>
              <div class="clearfix">
                <LazyLoad height={200} offset={100} once>
                    <img style={{width: "400px", border: "thick double #7d9ad4"}} src={finalisttakingpic} className="col-md-6 image-css-invert-none float-md-end mb-3 ms-md-3"/>
                </LazyLoad>

           

                <p className="comptitle" > 2024 International Finals Exam </p> 
                    
                    <p className="lowertitle">The International Finals Exam took place from <b>May 31st to June 2nd, 2024.</b></p>

                    <p className="lowertitle">The International Research Olympiad (IRO) is dedicated to honing analytical and critical thinking skills for student researchers—essential in scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested in the 2025 IRO examinations, allowing students to walk away with a deeper passion for scientific inquiry.</p>
              </div>
            </div>

            <BoardofAdvisors/>
            <div className='row gotdisatsm rowpad22 mainlinesectwo ' data-aos="fade-up" >
              <p className="comptitle" >Competition Format <IconButton onClick={handleOpen} style={{marginTop: '9px'}}><InfoOutlinedIcon style={{fontSize: '20px', color: 'black'}}/></IconButton></p> 
              <div id="sidelinesectwo" className='col-3'>
                <div className='row'>    
                  <p className="complowertitle"> <HowToRegIcon style={{fontSize: '40px'}}/> Registration</p>
                </div>

                <div className='row'>    
                  <div className="sepvert"></div>
                </div>
              
                <div className='row'>    
                  <p className="complowertitle" > <OpenInBrowserIcon style={{fontSize: '38px'}}/> Opens</p>
                </div>

                <div className='row'>    
                  <div className="sepvert"></div>
                </div>
                
                <div className='row'>    
                  <p className="complowertitle"> <MilitaryTechIcon style={{fontSize: '40px'}}/> Semifinals</p>
                </div>

                <div className='row'>    
                  <div className="sepvert"></div>
                </div>
              
                <div className='row'>    
                  <p className="complowertitle"> <EmojiEventsIcon style={{fontSize: '40px'}}/> Finals</p>
                </div>
              </div>
              <div id="secmainsectwo" className='col-9 '>
                <div className='row'>
                  <div className='col col-md' style={{marginRight: "20px", marginBottom: "20px"}}>
                    <p className="complowertitle"> <HowToRegIcon style={{fontSize: '40px'}}/> Registration</p>
                    <p className="date">February 2025</p>
                    <p className="lowertitle">

                    Register by March 3rd, 11:59pm EST to participate in the IRO. After registering, the IRO will create unique exam passwords for each student. Students will be sent their login information on March 4th, once registration is closed. Students will then compete in the Opens round.                      
                    </p>
                  </div>
                  <div className='col col-md'  style={{marginBottom: "20px"}}>
                    <p className="complowertitle" > <OpenInBrowserIcon style={{fontSize: '40px'}}/> Opens</p>
                    <p className="date">March 2025</p>
                    <p className="lowertitle">
                      
                    The "Opens" round is a comprehensive assessment, encompassing diverse elements such as analyzing research papers, interpreting scientific data, and tackling question formation and inquiry. This stage aims to test participants’ analytical skills and critical thinking.

                    </p>
                  </div>
                  
                </div>
                <div className='row'>
                  <div className='col col-md' style={{marginRight: "20px"}}>
                    <p className="complowertitle"> <MilitaryTechIcon style={{fontSize: '40px'}}/> Semifinals</p>
                    <p className="date">April 2025</p>
                      <p className="lowertitle">
                        
                      From the Open examinations, the top 10% of participants will advance to the semifinals. The semifinals elevate the challenge of introducing free response questions judged by qualified researchers delving deeper into participants' ability to articulate and apply their understanding of scientific concepts.
                      </p>
                  </div>
                  <div className='col col-md' >
                    <p className="complowertitle"> <EmojiEventsIcon style={{fontSize: '40px'}}/> Finals</p>
                    <p className="date">May 2025</p>
                    <p className="lowertitle">
                      
                    The pinnacle of the competition is the finals, where the top 15 participants will engage in intense competition for the coveted gold, silver, and bronze medals in the IRO. Additional sponsor prizes, media opportunities, and recognition will be awarded.
                      
                      </p>
                  </div>
                  
                </div>
              </div>
            </div>


            <div className="Competition-Format-resize-when-small-screen-hide-none">
              <div className='container'>
                <div className='row rowpad2 mainlinesectwo' data-aos="fade-up">
                  <p className="comptitleee" style={{paddingBottom: "50px"}}>Competition Format <IconButton onClick={handleOpen} style={{marginTop: '9px'}}><InfoOutlinedIcon style={{fontSize: '17px', color: 'black'}}/></IconButton></p> 
                    <div >

                      <div className='row' style={{marginRight: "20px", marginBottom: "20px"}}>
                        <p className="complowertitle"> <HowToRegIcon style={{fontSize: '40px'}}/> Registration</p>
                        <p className="date">February 2025</p>
                        <p className="lowertitle">

                      Register by March 3rd, 11:59pm EST to participate in the IRO. After registering, the IRO will create unique exam passwords for each student. Students will be sent their login information on March 4th, once registration is closed. Students will then compete in the Opens round.                          
                        </p>
                      </div>
                      <div className='row'  style={{marginBottom: "20px"}}>
                        <p className="complowertitle" > <OpenInBrowserIcon style={{fontSize: '40px'}}/> Opens</p>
                        <p className="date">March 2025</p>
                        <p className="lowertitle">
                          
                        The "Opens" round is a comprehensive assessment, encompassing diverse elements such as analyzing research papers, interpreting scientific data, and tackling question formation and inquiry. This stage aims to test participants’ analytical skills and critical thinking.

                        </p>
                      </div>
                      

                      <div className='row' style={{marginRight: "20px"}}>
                        <p className="complowertitle"> <MilitaryTechIcon style={{fontSize: '40px'}}/> Semifinals</p>
                        <p className="date">April 2025</p>
                          <p className="lowertitle">
                            
                          From the Open examinations, the top 10% of participants will advance to the semifinals. The semifinals elevate the challenge of introducing free response questions judged by qualified researchers delving deeper into participants' ability to articulate and apply their understanding of scientific concepts.
                          </p>
                      </div>
                      <div className='row' >
                        <p className="complowertitle"> <EmojiEventsIcon style={{fontSize: '40px'}}/> Finals</p>
                        <p className="date">May 2025</p>
                        <p className="lowertitle">
                          
                        The pinnacle of the competition is the finals, where the top 15 participants will engage in intense competition for the coveted gold, silver, and bronze medals in the IRO. Additional sponsor prizes, media opportunities, and recognition will be awarded.
                          
                          </p>
                      </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Partners/>



        
      <div className="container">

        <div className="row align-items-center rowpad1" data-aos="fade-up">

          <div className="col">

            <div style={{marginTop: '40px'}}> 
              <p style={{color: "#7d9ad4"}} className="wwhyiro">Why IRO?</p>
              
              <p className="wwhyirolowertitle">The International Research Olympiad (IRO) is dedicated to honing <b>analytical</b> and <b>critical thinking</b> skills for student researchers—essential in the realm of scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested in the 2025 IRO examinations, allowing students to walk away with a deeper passion for scientific inquiry.
              
             
               </p>
             
            </div>


          </div>
          <div className="col cool-image-guy-holding-blocks-od">

          <div style={{marginLeft: "50px"}} class="blibblob" ></div>
          
              <LazyLoad height={200} offset={100} once>
                <img style={{width: "390px", marginTop: "40px"}} src={whyiroo} className="cool-image-guy-holding-blocks"/>
            </LazyLoad>
          </div>

        
        </div>
    
          

      </div>
    </div>
  );
}

export default Home;