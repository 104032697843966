import React from 'react'
import '../App.css'
import winnerMain from '../finalistsheadshot/winner-main.webp'

import LazyLoad from 'react-lazyload';
import admit from "../finalistsheadshot/amit.webp"
import Ethan from "../finalistsheadshot/gt.webp"
import Jaansi from "../finalistsheadshot/pe.webp"
import Trashgim from "../finalistsheadshot/go.png"
import Sakethram from "../finalistsheadshot/wid.png"
import Edward from "../finalistsheadshot/cm.webp"
import Narendran from "../finalistsheadshot/sik.webp"
import Nguyen from "../finalistsheadshot/naugh.webp"
import Vishnu from "../finalistsheadshot/iad.webp"
import Ameya from "../finalistsheadshot/id.webp"
import Antara from "../finalistsheadshot/uil.webp"
import Ruuhan from "../finalistsheadshot/ruhan.png"

function WinnersThree() {
  return (
    <div>
        <div>
            <div className="cool-background">
                <div className="container">
                    <div>
                        <LazyLoad once offset={100}>
                            <img src={winnerMain} className="cool-image-guy-holding-blocks" style={{width: '100%'}}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <div className="container">
                <div style={{marginTop: '30px'}}>
                    <p style={{fontSize: '27px'}}><b>Our 2024 IRO Winners</b></p>

                    <p>The International Finals Exam took place from May 31st to June 2nd, 2024.
                        <br/>
Rishab Kumar Jain, President and Chair of the Student Board of Advisors to the International Research Olympiad said "What sets the IRO apart is our commitment to critical thinking, which is essential for scientific research. We've seen a high number of contestants who possess extraordinary potential-and who are serious and motivated about their research-based, real-world interests."<br/><br/>
"The IRO Finals took place in a time like no other, and students showed a level of adaptability and resilience that was remarkable," said Executive & Education Coordinator Yashvir Sabharwal. "Each student tackled a crucial issue, and presented their solutions so that they would matter to the world today."<br/><br/>
The 2024 IRO Finals showcased some of the most promising young minds in the world. With its dedication to critical thinking and real-world application of knowledge, the IRO is shaping the next generation of innovative leaders who will tackle global challenges and make a lasting impact on society.<br/><br/>
View the entire Press Release <a href="/press-releases" style={{color: 'black'}}>here</a></p>
                </div>

                <div className="visible-winners">
                    <div>
                        <div>
                            <p style={{fontWeight: '600', fontSize: '14px'}}>Gold Medal:</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Jaansi} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Jaansi Patel</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>New Jersey, of Phillips Exeter Academy, won 1st Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Jaansi Patel is a 16 year old junior from New Jersey, and she is currently studying at Phillips Exeter Academy in New Hampshire. She is passionate about applying neuropsychology research for mental health advocacy. In her free time, Jaansi enjoys running her school's annual TED event, teaching computer science to fellow Girl Scouts, and hosting LearnOn Podcast, the science show by kids, for kids, with her younger brother.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <p style={{fontWeight: '600', fontSize: '14px'}}>Silver Medal:</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Edward} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Edward Kang</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Upper Saddle River, New Jersey, of Bergen County Academies won 2nd Place and a Silver Medal.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>I'm a sophomore in high school from Upper saddle river in Northern New Jersey. I've moved several times between the us and South Korea, and I've been through American, Korean, and British education systems. I started learning machine learning through online courses for my research about two years ago, and I have conducted research into diagnosing Autism Spectrum Disorder and ADHD using retinal images. Beyond research, I'm a Baritone in my school's and church's choirs, a chess player and teacher, and a newbie at badminton.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Antara} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Antara Chhabra</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Bengaluru, India, of Shri Ram Global School won 3rd Place and a Silver Medal.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>I am Antara Chhabra, a grade 12 student from Bengaluru, India. My interests range from reading books of various genres to engaging in scientific discussion and research to enhance my knowledge and aid in my journey of continuous learning. Playing basketball, singing, and writing poems, songs, and stories are what I enjoy in my spare time. What I'm extremely passionate about is doing my bit to help make the world a better place for all of us and to leave a dent in the universe!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <p style={{fontWeight: '600', fontSize: '14px'}}>Bronze Medal:</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Vishnu} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Vishnu Mangipudi</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Seattle, Washington, of Lakeside School won 4th Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Vishnu Mangipudi is a sophomore at the Lakeside School who has a deep passion for learning more about the world around him. He is very interested in the intersection of computer programming and the scientific disciplines in the addressing of many of our world's challenges. In his research, Vishnu has coded machine-learning models addressing environmental and medical issues, including models that diagnose heart arrhythmias and analyze regions of glacial calving from satellite data. In his free time, Vishnu loves to sing in his local choir, volunteer with his city, and share his knowledge with his community by teaching STEM classes.</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Ruuhan} style={{ height: "230px", width: "185px" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Ruuhan Anand</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Singapore, of Raffles Institution, won 5th Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Ruuhan is a student at Raffles Institution (class of 2025) with a burgeoning passion for biology, chemistry, and biomedical research. While he hopes to pursue a career in medicine or research in the future, you can find him cafe-hopping, reading fiction or binging anime series in his free time.</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Ethan} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Ethan Sullivan</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Miami, Florida, of Ransom Everglades School won 6th Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Ethan Sullivan is currently a junior at Ransom Everglades School in Miami, Florida. A dedicated scholar-athlete, Ethan is the president of his school's Mu Alpha Theta chapter while also serving as captain of the variety cross country team and earning three-time All-American honors in triathlon. Ethan's passion for neuroscience and community service led to his Eagle Scout project focused on improving stroke outcomes in underserved communities. He was recently selected for the prestigious Coolidge Senators program and received a Bowden Fellowship to study the motivations of thru-hikers on the Appalachian Trail.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
                
                <div className="hidden-winners">
                    <div>
                        <div>
                            <p style={{fontWeight: '600', fontSize: '14px'}}>Gold Medal:</p>
                            <div>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Jaansi} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Jaansi Patel</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>New Jersey, of Phillips Exeter Academy, won 1st Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Jaansi Patel is a 16 year old junior from New Jersey, and she is currently studying at phillips Exeter Academy in New Hampshire. She is passionate about applying neuropsychology research for mental health advocacy. In her free time, Jaansi enjoys running her school's annual TED event, teaching computer science to fellow Girl Scouts, and hosting LearnOn Podcast, the science show by kids, for kids, with her younger brother.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <p style={{fontWeight: '600', fontSize: '14px'}}>Silver Medal:</p>
                            <div>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Edward} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Edward Kang</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Upper Saddle River, New Jersey, of Bergen County Academies won 2nd Place and a Silver Medal.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>I'm a sophomore in high school from Upper saddle river in Northern New Jersey. I've moved several times between the us and South Korea, and I've been through American, Korean, and British education systems. I started learning machine learning through online courses for my research about two years ago, and I have conducted research into diagnosing Autism Spectrum Disorder and ADHD using retinal images. Beyond research, I'm a Baritone in my school's and church's choirs, a chess player and teacher, and a newbie at badminton.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{marginTop: '20px'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Antara} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Antara Chhabra</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Bengaluru, India, of Shri Ram Global School won 3rd Place and a Silver Medal.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>I am Antara Chhabra, a grade 12 student from Bengaluru, India. My interests range from reading books of various genres to engaging in scientific discussion and research to enhance my knowledge and aid in my journey of continuous learning. Playing basketball, singing, and writing poems, songs, and stories are what I enjoy in my spare time. What I'm extremely passionate about is doing my bit to help make the world a better place for all of us and to leave a dent in the universe!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div>
                            <p style={{fontWeight: '600', fontSize: '14px'}}>Bronze Medal:</p>
                            <div>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Vishnu} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Vishnu Mangipudi</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Seattle, Washington, of Lakeside School won 4th Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Vishnu Mangipudi is a sophomore at the Lakeside School who has a deep passion for learning more about the world around him. He is very interested in the intersection of computer programming and the scientific disciplines in the addressing of many of our world's challenges. In his research, Vishnu has coded machine-learning models addressing environmental and medical issues, including models that diagnose heart arrhythmias and analyze regions of glacial calving from satellite data. In his free time, Vishnu loves to sing in his local choir, volunteer with his city, and share his knowledge with his community by teaching STEM classes.</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Ruuhan} style={{ height: "230px", width: "185px" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Ruuhan Anand</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Singapore, of Raffles Institution, won 5th Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Ruuhan is a student at Raffles Institution (class of 2025) with a burgeoning passion for biology, chemistry, and biomedical research. While he hopes to pursue a career in medicine or research in the future, you can find him cafe-hopping, reading fiction or binging anime series in his free time.</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                <div>
                                    <LazyLoad once offset={100}>
                                        <img src={Ethan} style={{ height: "230px", width: "auto" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                                    </LazyLoad>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title" style={{fontSize: '21px'}}><b>Ethan Sullivan</b></h5>
                                    <p className="card-text" style={{marginTop: '10px', fontSize: '17px'}}>Miami, Florida, of Ransom Everglades School won 6th Place.</p>
                                    </div>
                                    <div style={{marginTop: '20px', fontSize: '17px'}}>
                                        <p>Ethan Sullivan is currently a junior at Ransom Everglades School in Miami, Florida. A dedicated scholar-athlete, Ethan is the president of his school's Mu Alpha Theta chapter while also serving as captain of the variety cross country team and earning three-time All-American honors in triathlon. Ethan's passion for neuroscience and community service led to his Eagle Scout project focused on improving stroke outcomes in underserved communities. He was recently selected for the prestigious Coolidge Senators program and received a Bowden Fellowship to study the motivations of thru-hikers on the Appalachian Trail.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            
            </div>
        </div>

    </div>
  )
}

export default WinnersThree
