import '../App.css';
import inspirtai from "../logos/Inspirit_AI.webp"
import sciguide from "../logos/Sciguide.webp"
import algoverse from "../logos/algoverse.webp"
import sanes from '../logos/sanes.webp'
import whitecoat from "../logos/white_coat.webp"
import LazyLoad from 'react-lazyload';
import nexttech from '../logos/NexTech.webp';
import endavor from '../logos/endeavor.webp';
import engineerlogo from '../logos/Engineering_Pivot_x_STEMed_Insights_LE_auto_x2.webp';
import stemlogo from '../logos/stemiverse_logo_no_bg.webp';
import medlogo from '../logos/MED-removebg-preview.webp';
import logotwo from '../logos/plogo.png';
import yri from "../logos/yri.png"
import logotwo1 from '../logos/halfglobe.png'; 
import asdan from "../logos/ASDAN.webp"
import logotwo2 from '../logos/hypelogo.png'; 
import logotwo3 from '../logos/samyaklogo.png'; 
import logotwo5 from '../logos/ylogo.png'; 
import olpiamdhub from "../logos/perpolympiadhub.png"
import logofunny from '../logos/Fruit_logo.png'; 
import lurmerelogo from '../logos/lumerelogo.png';
import biologo from '../logos/biologo.png'; 
import dialogo from "../logos/dialogogo.jpg"
import qlogo from "../logos/Qurios.jpg"
import googlelogo from '../logos/google.png'; 
import loomlogo from '../logos/newloom.png'; 
import desmos from '../logos/desmos.png'; 
import Typography from '@mui/material/Typography';
import "aos/dist/aos.css"; 



function Partners() {

    const asdanClick = () => {
        window.location.href = "https://www.seedasdan.com/en/home-en/";

    }

    const lurmerelogoClick = () => {
        window.location.href = "https://www.lumiere-education.com/";
    }

    const samyakClick = () => {
        window.location.href = "https://samyakscience.weebly.com/";
    }

    const QuriosClick = () => {
        window.location.href = "https://www.linkedin.com/company/qurios-institute/";
    }

    const PrepOlmpiadsClick = () => {
        window.location.href = "https://www.linkedin.com/company/prepolimpiadas/";
    }

    const InspiritAIClick = () => {
        window.location.href = "https://inspiritai.com";
    }

    const AlogverseClick = () => {
        window.location.href = "https://algoverseairesearch.org/";
    }

    const GoogleClick = () => {
        window.location.href = "https://www.google.com/nonprofits/";
    }

    const YriClick = () => {
        window.location.href = "https://www.youthresearchinitiative.org/";
    }

    const InternationalBiologyBowlClick = () => {
        window.location.href = "https://biologybowl.org/";
    }

    const DesmosClick = () => {
        window.location.href = "https://www.desmos.com/";
    }

    return (
        <div>
            <section class="py-3 py-md-5 py-xl-8">
            <div class="container">
                <div class="row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                    <h2 class="fs-6 text-secondary mb-2 text-center">IRO thanks the following organizations for their support.</h2>
                    <h2 class="mb-4 display-5 text-uppercase text-center">Our Partners </h2>
                    <p className="fs-5 mb-1 partner-Platinum text-center" style={{ color: '#E5E4E2' }}>Platinum</p>

                    <hr class="w-50 mx-auto mb-2 mb-xl-9 border-dark-subtle"/>
                </div>
                </div>
            </div>

            <div class="container overflow-hidden text-center">
                <div class="row gy-4 justify-content-center">
                <div onClick={asdanClick} style={{cursor: "pointer"}} class="Col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="80px" className="no-invert-image-partners-css" src={asdan}></img>

                    <br/> <br/>
              
                    ASDAN and ASDAN China
                    </div>
                </div>
                <div onClick={lurmerelogoClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="35px" className="no-invert-image-partners-css" src={lurmerelogo}></img>

                    <br/> <br/>
              
                    Lumiere Education & The Lumiere Research Inclusion Foundation 501(c)(3)
                    </div>
                </div>
                <div onClick={samyakClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="45px" className="no-invert-image-partners-css" src={logotwo3}></img>

                    <br/> <br/>
              
                    Samyak Science Society 501(c)(3)
                    </div>
                </div>
               
              
                </div>
            </div>

            <div style={{paddingTop: "50px"}}></div>

            <div class="container">
                <div class="row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                    
                    <p class="fs-5 mb-1 partner-Gold text-center" style={{ color: '#FFD700' }}>Gold</p>
                    <hr class="w-50 mx-auto mb-2 mb-xl-9 border-dark-subtle"/>
                </div>
                </div>
            </div>

            <div class="container overflow-hidden">
                <div class="row gy-4 justify-content-center">
                <div onClick={QuriosClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="40px" className="no-invert-image-partners-css" src={qlogo}></img>

                    <br/> <br/>
              
                    Qurios Institute, QRA Inc.
                    </div>
                </div>
                <div onClick={PrepOlmpiadsClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="35px" className="no-invert-image-partners-css" src={logotwo}></img>

                    <br/> <br/>
              
                    Prep Olimpíadas
                    </div>
                </div>
                <div onClick={InspiritAIClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="40px" className="no-invert-image-partners-css" src={inspirtai}></img>

                    <br/> <br/>
              
                    Inspirit AI
                    </div>
                </div>
                <div onClick={AlogverseClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="40px" className="no-invert-image-partners-css" src={algoverse}></img>

                    <br/> <br/>
              
                    Algoverse
                    </div>
                </div>
                <div onClick={GoogleClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="45px" className="no-invert-image-partners-css" src={googlelogo}></img>

                    <br/> <br/>
              
                    Google for Nonprofits
                    </div>
                </div>
             
               
              
                </div>
            </div>

            <div style={{paddingTop: "50px"}}></div>

            <div class="container">
                <div class="row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                    
                    <p class="fs-5 mb-1 partner-Silver text-center" style={{ color: '#C0C0C0' }}>Silver</p>
                    <hr class="w-50 mx-auto  mb-2 mb-xl-9 border-dark-subtle"/>
                </div>


                </div>
            </div>

            <div class="container overflow-hidden">
                <div class="row gy-4 justify-content-center">

                <div onClick={YriClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="45px" style={{filter: "invert(100%)"}} className=" no-invert-image-partners-css" src={yri}></img>

                    <br/> <br/>
              
                    Youth Research Initiative
                    </div>
                </div>

                <div onClick={InternationalBiologyBowlClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="40px" className="no-invert-image-partners-css" src={biologo}></img>

                    <br/> <br/>
              
                    International Biology Bowl
                    </div>
                </div>

                <div onClick={DesmosClick} style={{cursor: "pointer"}} class="col-6 col-md-4 col-xl-3 text-center">
                    <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
                    <img width="32px" className="pt-1 no-invert-image-partners-css" src={desmos}></img>

                    <br/> <br/>
              
                    Desmos
                    </div>
                </div>
             
               
              
                </div>
            </div>

            <div style={{paddingTop: "50px"}}></div>

            <div class="container">
                <div class="row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                    
                    <p class="fs-5 mb-1 partner-Bronze text-center" style={{ color: '#CD7F32' }}>Bronze</p>
                    <hr class="w-50 mx-auto  mb-2 mb-xl-9 border-dark-subtle"/>
                </div>
                </div>
            </div>

            <div class="container overflow-hidden">
                <div class="row mt-4 gy-4 justify-content-center">
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={logotwo5}></img>

                    <br/> <br/>
              
                    Youth Medical Journal
               
                </div>
                <div class="col-6 col-md-4 col-xl-3 text-center">

                    <img width="45px" className="no-invert-image-partners-css" src={logotwo1}></img>

                    <br/> <br/>
              
                    SySTEM for Success 501(c)(3)
     
                </div>
                <div class="col-6 col-md-4 col-xl-3 text-center">
            
                    <img width="45px" className="no-invert-image-partners-css" src={logotwo2}></img>

                    <br/> <br/>
              
                    HYPE 501(c)(3)

   
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
          
                    <img width="45px" className="no-invert-image-partners-css" src={olpiamdhub}></img>

                    <br/> <br/>
              
                    OlympiadPrepHub
            
                </div>

                <div class="col-6 col-md-2 col-xl-2 text-center">
               
                    <img width="45px" className="no-invert-image-partners-css" src={logofunny}></img>

                    <br/> <br/>
              
                    Pandorax
                 
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={dialogo}></img>

                    <br/> <br/>
              
                    Doism
                  
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={engineerlogo}></img>

                    <br/> <br/>
              
                    Engineering Pivot
                 
                </div>

                <div class="col-6 col-md-2 col-xl-2 text-center">
               
                    <img width="45px" className="no-invert-image-partners-css" src={stemlogo}></img>

                    <br/> <br/>
              
                    STEMEd Insights
                 
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
               
                    <img width="45px" className="no-invert-image-partners-css" src={medlogo}></img>

                    <br/> <br/>
              
                    Medmun Society
                 
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={loomlogo}></img>

                    <br/> <br/>
              
                    Everyone STEM
                  
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={nexttech}></img>

                    <br/> <br/>
              
                    <p className='mt-2'>Project NexTech</p>
                  
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={endavor}></img>

                   
              
                    <p className='mt-3'>Endeavor</p>
                  
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={sciguide}></img>

                    <br/> <br/>
              
                    SciGuide
                  
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={whitecoat}></img>

                    <br/> <br/>
              
                    White Coat
                  
                </div>
                <div class="col-6 col-md-2 col-xl-2 text-center">
                 
                    <img width="45px" className="no-invert-image-partners-css" src={sanes}></img>

                   
              
                    <p className='mt-3'>Sanes</p>
                  
                </div>
             
             
               
              
                </div>
            </div>

            <div style={{paddingTop: "50px"}}></div>

            <div class="container">
                <div class="row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                    
                    <p class="fs-5 mb-5 text-center" >We are always looking to have new sponsors and partners join IRO! Please email us at <a style={{color: "#7d9ad4"}} href="mailto:iro@samyakscience.com">iro@samyakscience.com</a> if you are interested.</p>
                </div>
                </div>
            </div>
            </section>
        </div>
    )

}


export default Partners;

