import React from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
 
function RulesandRegulations() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }
  return (
    <div>
      <div className="container">
        <div class="row rowpad1">
          <div class="col-4">
            <nav id="navbar-example3" class="h-100 flex-column align-items-stretch pe-4 border-end">
              <nav class="nav nav-pills flex-column">
                <a class="nav-link" href="#item-1" className="bigblueonthewing" style={{fontSize: '17px'}}>IRO Rules & Honor Code</a>
                {/* <nav class="nav nav-pills flex-column">
                  <a class="nav-link ms-3 my-1" href="#item-1-1">Item 1-1</a>
                  <a class="nav-link ms-3 my-1" href="#item-1-2">Item 1-2</a>
                </nav> */}
                <a class="nav-link" href="#item-2" className="bigblueonthewing" style={{fontSize: '17px', marginTop: '5px'}}>IRO Fee Waiver Policy</a>
                <a class="nav-link" href="#item-3" className="bigblueonthewing" style={{fontSize: '17px', marginTop: '5px'}}>IRO Privacy Policy</a>
                {/* <nav class="nav nav-pills flex-column">
                  <a class="nav-link ms-3 my-1" href="#item-3-1">Item 3-1</a>
                  <a class="nav-link ms-3 my-1" href="#item-3-2">Item 3-2</a>
                </nav> */}
                <a class="nav-link" href="#item-4" className="bigblueonthewing" style={{fontSize: '17px', marginTop: '5px'}}>Terms of Conditions</a>
              </nav>
            </nav>
          </div>

          <div class="col-8">
            <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" class="scrollspy-example-2" tabindex="0">
              <div id="item-1">
                <h4>IRO Rules & Honor Code</h4>
            
                <li>LONG
                  <ul>
                    <li>All competition tasks, research, and submissions must be independently completed by the competitor. Assistance from mentors, teachers, family members, or any other external individuals is strictly prohibited.</li>
                    <br/>
                    <li>The use of generative AI, as well as other automated tools or software that significantly enhance research capabilities beyond standard means, is strictly prohibited. This includes but is not limited to, ChatGPT, chatbots, advanced data analysis tools, extensive code libraries, and any software that automates the research process.</li>
                    <br/>
             
                    <li>
                    Competitors are prohibited from using VPNs, proxy servers, TOR networks, or any other technology that obscures or alters their real-time location, identity, or facilitates anonymous internet access during the competition.

                    </li>
                    <br/>
                    <li>
                    Direct or indirect communication with other competitors regarding any aspect of the competition is prohibited. This includes, but is not limited to, discussions on public forums, social media, or through intermediaries. The exchange of competition-related information in any form is strictly forbidden. Competitors must not share, distribute, or publish competition questions, problems, or materials with anyone, both during and after the competition, unless explicitly authorized by the organizers.

                    </li>
                    <br/>
                    <li>
                    Competitors must not exploit system vulnerabilities or technical glitches, or use any unfair means to gain access to restricted information, extend time limits, or otherwise breach the competition's integrity. This includes but is not limited to, the use of multiple logins, hacking, or manipulating the competition platform.

                    </li>
                    <br/>
                    <li>
                    The competition organizers will monitor compliance with these rules. Any violation will lead to immediate disqualification and potential further consequences. It is the responsibility of each competitor to adhere to these rules and uphold the spirit of fair competition.

                    </li>
                    <br/>
                    <li>
                    All participants are expected to read and follow the Terms of Service (TOS). There is no exception to any of these rules.

                    </li>
                  </ul>
                </li>
                <li>ABRIDGED
                  <ul>
                    <li>All competition and work during the IRO must be done by yourself and not with a team.</li>
                    <br/>
                    <li>The use of generative AI and AI tools is strictly prohibited.</li>
                    <br/>
                    <li>
                    Competitors must not use a VPN during competition.

                    </li>
                    <br/>
                    <li>
                    Communicating with other competitors during and about the contest is prohibited.

                    </li>
                    <br/>
                    <li>
                    The use of multiple logins to access the contest is strictly prohibited.

                    </li>
                  </ul>
                </li>
                <p>

                Honor Code: <br/>
As a participant in the International Research Olympiad, I pledge to uphold the highest standards of integrity, honesty, and ethical conduct. I commit to submitting only original work, giving proper attribution, and avoiding any form of plagiarism or dishonest behavior. I will respect collaboration rules, handle data transparently, and maintain the confidentiality of shared information. My conduct will be professional and respectful, and I will adhere to all competition rules. I accept responsibility for my actions, will report any violations, and embrace the principles of fair competition. I understand that any breach of this commitment may lead to consequences, including disqualification.
                </p>
                <hr/>
              </div>
              {/* <div id="item-1-1">
                <h5>Item 1-1</h5>
                <p>...</p>
              </div>
              <div id="item-1-2">
                <h5>Item 1-2</h5>
                <p>...</p>
              </div> */}
              <div id="item-2">

                <h4>IRO Fee Waiver Policy</h4>
                <p>At the International Research Olympiad (IRO), we are committed to providing equal opportunities for participation. We understand that financial constraints may pose challenges for some individuals. As part of our commitment to inclusivity, we of{''}fer a Fee Waiver Policy to eligible participants. This policy is not a legal document.</p>

                <p><b>How to Apply for a Fee Waiver:</b></p>
                <p>To request a fee waiver, please email <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a>. The email should include:</p>
              <ul>
                  <li><strong>Subject:</strong> Fee Waiver Request - [First Name, Last Name]</li>
                  <li>The username you used to open an account on the IRO website.</li>
                  <li>A brief explanation of the financial hardship or special circumstances.</li>
              </ul>
              <p>We strongly encourage applicants to also attach documentation that shows you meet at least one of the following:</p>
              <ul>
                  <li>Your annual family income falls within the income eligibility guidelines set by the USDA Food and Nutrition Service for reduced price or free meals, or you are enrolled in the federal free or reduced price lunch program (FRPL).*</li>
                  <li>You are currently experiencing financial hardship that prevents you from paying the registration fee, including unexpected medical bills or emergencies.</li>
                  <li>You have received or are eligible to receive an SAT or ACT fee waiver.</li>
                  <li>You have received or are eligible to receive a Common App fee waiver.</li>
                  <li>Your family receives public assistance.</li>
                  <li>You are enrolled in a federal, state, or local program that aids students from low-income families (e.g., GEAR UP, TRIO such as Upward Bound or others).</li>
                  <li>You live in a federally subsidized public housing, a foster home or are homeless.</li>
                  <li>You are a ward of the state or an orphan.</li>
                  <li>You have received or are eligible to receive a Pell Grant.</li>
                  <li>You can provide a supporting statement from a school of{''}ficial, college access counselor, financial aid of{''}ficer, or community leader.</li>
              </ul>
              <p><em>*You must enroll or be eligible for the FRPL. Students who attend schools where all students receive free lunch do not automatically qualify for an International Research Olympiad fee waiver.</em></p>

              <p><b>Approval Process:</b></p>
              <p>Fee waiver requests will be reviewed on a case-by-case basis. Approval for a fee waiver is based on the information provided, the available resources, and any other circumstances up to our sole discretion. Participants will be notified of the decision via email within 2 weeks. Fee waivers are never guaranteed. The deadline for fee waiver requests is 11:59 PM EST, March 3th, 2025. Requests submitted after this deadline will not be considered.</p>

              <p><b>Important Notes:</b></p>

              <ul>
                  <li>Do your best to send an application as early as possible. If you submit too close to the application deadline (even if you still submit before it), we may not have time to review your request, and you may be denied a fee waiver despite otherwise being eligible.</li>
                  <li>Participants who are granted fee waivers will receive instructions on completing their registration without payment.</li>
                  <li>Financial barriers should not hinder access to educational opportunities, and we strive to support all deserving participants in their journey with the International Research Olympiad.</li>
              </ul>

              <p>For any questions or concerns regarding fee waivers, please contact us at <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a></p>
              
              <hr/>

              </div>
              <div id="item-3">
                <h4>IRO Privacy Policy</h4>
                <p></p>
                <p><b>INTERNATIONAL RESEARCH OLYMPIAD PRIVACY POLICY</b></p>

                <p>The International Research Olympiad (the “Company”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.</p>

                <p>For purposes of this Agreement, “Site” refers to the Company’s website, which can be accessed at <a href="https://internationalresearcholympiad.com/">the International Research Olympiad</a>. “Service” refers to the Company’s services accessed via the Site, in which users can register to participate in the International Research Olympiad. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of our Site or our Service. By accessing our Site or our Service, you accept our Privacy Policy and Terms of Use, and you consent to our collection, storage, use, and disclosure of your Personal Information as described in this Privacy Policy.</p>

                <p><b>I. INFORMATION WE COLLECT</b></p>

                <p>We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit, and preferences that are generated based on the data you submit and number of clicks. Personal Information includes your email, date of birth, full legal name, address, contact information, and anything additional to identify You, which you submit to us through the registration process at the Site.</p>

                <p><em>1. Information collected via Technology</em></p>

                <p>To activate the Service you do not need to submit any Personal Information other than your email address. To use the Service thereafter, you do need to submit further Personal Information which may include your email, date of birth, full legal name, address, contact information, and anything additional to identify you. However, in an ef{''}fort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. For example, the Company may use cookies to collect the following information:</p>
                
                <ul>
                  <li>Internet Protocol (IP) Address</li>
                  <li>Device information</li>
                  <li>Browser information</li>
              </ul>

              <p>The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.</p>

          

                <p><em>2. Information you provide us by registering for an account</em></p>

                <p>In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address, and creating a username and a password. By registering, you are authorizing us to collect, store, and use your email address in accordance with this Privacy Policy.</p>


                <p><em>3. Children’s Privacy</em></p>

                <p>The Site and the Service are not directed to anyone under the age of 13. The Site does not knowingly collect or solicit information from anyone under the age of 13, or allow anyone under the age of 13 to sign up for the Service. In the event that we learn that we have gathered personal information from anyone under the age of 13 without the consent of a parent or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please contact us at <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a>.</p>
   

                <p><b>II. HOW WE USE AND SHARE INFORMATION</b></p>

                <p><em>Personal Information:</em></p>

                <p>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user’s email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy.
In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional of{''}fers.
</p>

                <p>We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law. </p>

                <p><em>Non-Personal Information:</em></p>

                <p>In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.</p>
         
               <p>In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.</p>
                <p><b>III. HOW WE PROTECT INFORMATION</b></p>

                <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.</p>
      

                <p><b>IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</b></p>

                <p>When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that you do not wish to receive marketing communications from us by contacting us at <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a>. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out by emailing us, we may continue to send you administrative emails including, for example, periodic updates to our Privacy Policy.
</p>
     

                <p><b>V. LINKS TO OTHER WEBSITES</b></p>

                <p>As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.</p>


                <p><b>VI. CHANGES TO OUR PRIVACY POLICY</b></p>

                <p>The Company reserves the right to change this policy and our Terms of Service at any time.  We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into ef{''}fect at least 7 days following such notification. Non-material changes or clarifications will take ef{''}fect immediately. You should periodically check the Site and this privacy page for updates.</p>
 

                <p><b>VII. CONTACT US</b></p>
                <p>If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to <a href="mailto:iro@samyakscience.com">iro@samyakscience.com</a>. Last Updated: This Privacy Policy was last updated on January 20th, 2025.</p>
                <hr/>
              </div>
              <div id="item-4">
                <h4>Terms and Conditions</h4>
                <p></p>
                <iframe className="termsacondsitonfs custmabominat" src="https://docs.google.com/document/d/e/2PACX-1vTHZ-_OdNXn9k3IV0f4kFnzHv817BD43cphfRCvUogZpklu8M_VYRxlaYbEHEMxsXJIVA4C3vxfwceM/pub?embedded=true"></iframe>
                <a className="iphonesmalterms" href="/terms-of-service">Go to Terms and Service</a>
              </div>
              {/* <div id="item-3-1">
                <h5>Item 3-1</h5>
                <p>...</p>
              </div>
              <div id="item-3-2">
                <h5>Item 3-2</h5>
                <p>...</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row rowpad1" style={{alignItems: "center", justifyContent: "center"}}>
        
         

        </div>
      </div>
    </div>
  )
}

export default RulesandRegulations
